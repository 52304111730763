import {
  AddShoppingCart,
  Delete,
  LocalTaxi,
  PersonSearch,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { api } from 'common';
import { CircularLoading } from 'components';
import { Roles } from 'models';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Android12Switch, ViewCarDetails, ViewUserDetails } from './Components';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { CarCreateForm } from 'forms';
import { object } from 'prop-types';

const DriverPage2 = () => {
  const auth = useSelector(state => state.auth);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const {
    fetchUsersOnce,
    deleteUser,
    updateUserApproval,
    updateUserServiceStatus,
    updateUserCar,
    updateCarAprroval,
    updateDriverActiveStatus,
    updateUserWalletBalance,
  } = api;
  const loaded = useRef(false);
  const [rows, setRows] = useState([]);
  const { handleOpenDrawer, updateContent } = useContext(DrawerContext);
  const [cityTab, setCityTab] = useState('Cúcuta');

  const handleDeleteUser = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id));
        dispatch(fetchUsersOnce());
        Swal.fire('¡Eliminado!', 'El conductor ha sido eliminado.', 'success');
      }
    });
  };

  const updateApproval = async (id, approved) => {
    try {
      await updateUserApproval(id, approved);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        'Se ha actualizado la aprobación del conductor',
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleUserApproval = (id, approved) => {
    Swal.fire({
      title: `¿Estas seguro de ${
        !approved ? 'desaprobar' : 'aprobar'
      } este conductor?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !approved ? 'Desaprobar' : 'Aprobar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateApproval(id, approved);
      }
    });
  };

  const updateService = async (id, queue) => {
    try {
      await updateUserServiceStatus(id, queue);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        `Se ha ${!queue ? 'desactivado' : 'activado'} el conductor`,
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleUserService = (id, queue) => {
    Swal.fire({
      title: `¿Estas seguro de ${
        !queue ? 'desactivar' : 'activar'
      } este conductor?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !queue ? 'Desactivar' : 'Activar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateService(id, queue);
      }
    });
  };

  const updateCarApproval = async (userId, carId, approved) => {
    dispatch(
      updateUserCar(userId, {
        carApproved: !approved,
      }),
    );
    dispatch(updateCarAprroval(carId, !approved));
    dispatch(fetchUsersOnce());
  };

  const handleCarApproval = (row, approved) => {
    const { id: user, carId: id } = row;
    Swal.fire({
      title: `¿Estas seguro de ${
        !approved ? 'desaprobar' : 'aprobar'
      } este coche?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !approved ? 'Desaprobar' : 'Aprobar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateCarApproval(user, id, approved ? false : true);
      }
    });
  };

  const updateDriverStatus = async (id, driverActiveStatus) => {
    try {
      await updateDriverActiveStatus(id, driverActiveStatus);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        `Se ha puesto en ${
          driverActiveStatus ? 'servicio' : 'descanso'
        } al conductor`,
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleDriverActiveStatus = async (id, driverActiveStatus) => {
    Swal.fire({
      title: `¿Estas seguro de cambiar al estado ${
        driverActiveStatus ? 'Descansando' : 'En servicio'
      } al conductor?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: driverActiveStatus ? 'Descansando' : 'En servicio',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateDriverStatus(id, !driverActiveStatus);
      }
    });
  };

  const updateWalletBalance = async (id, walletBalance) => {
    try {
      await updateUserWalletBalance(id, walletBalance);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        `Se ha actualizado el saldo del conductor`,
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleWalletBalance = async (id, walletBalance) => {
    Swal.fire({
      title: `Modificar el saldo del conductor`,
      icon: 'warning',
      input: 'number',
      inputAttributes: {
        min: 0,
        step: 100,
        value: walletBalance,
      },
      inputValidator: value => {
        const numValue = parseFloat(value); // Convertir a número
        if (isNaN(numValue)) {
          return 'Debes ingresar un valor válido';
        }
        if (numValue < 0) {
          return 'El valor debe ser positivo';
        }
      },
      inputValue: walletBalance,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(result => {
      if (result.isConfirmed) {
        // Usamos el valor ingresado, no el valor original
        updateWalletBalance(id, parseFloat(result.value));
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 100,
        getActions: params => {
          let actions = [
            <ViewUserDetails
              label="Detalles del conductor"
              icon={<PersonSearch />}
              userId={params.id}
              closeMenuOnClick={false}
            />,
          ];

          if (params.row.vehicleNumber) {
            actions.push(
              <ViewCarDetails
                label="Detalles del coche"
                icon={<LocalTaxi color="secondary" />}
                carId={params.row.id}
                closeMenuOnClick={false}
                props={params.row}
              />,
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<AddShoppingCart />}
                label="Asignar coche"
                name="Asignar coche"
                onClick={() => {
                  updateContent(<CarCreateForm userId={params.id} />);
                  handleOpenDrawer();
                }}
              />,
            );
          }

          return actions;
        },
      },
      {
        field: 'createdAt',
        type: 'Date',
        headerName: 'Creado',
        width: 100,
        valueGetter: (params, row) => {
          // Asegúrate de convertir createdAt a un objeto Date para la ordenación
          return row.createdAt ? new Date(row.createdAt) : null;
        },
        valueFormatter: (params, row) => {
          // Formatea la fecha para mostrarla en el formato deseado
          return row.createdAt
            ? new Date(row.createdAt).toLocaleDateString('en-GB') // Día/Mes/Año
            : '';
        },
      },
      {
        field: 'approved',
        type: 'boolean',
        headerName: 'Documentos aprobados',
        width: 100,
        renderCell: params => (
          <Android12Switch
            checked={params.row.approved}
            onChange={() => {
              handleUserApproval(params.id, !params.row.approved);
            }}
          />
        ),
      },
      {
        field: 'carApproved',
        type: 'string',
        headerName: 'Coche aprobado',
        width: 100,
        renderCell: params => (
          <Android12Switch
            checked={params.row.carApproved}
            onChange={() => {
              handleCarApproval(params.row, !params.row.carApproved);
            }}
          />
        ),
      },
      {
        field: 'driverActiveStatus',
        type: 'string',
        headerName: 'Estado',
        width: 150,
        renderCell: params => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Android12Switch
                checked={params.row.driverActiveStatus}
                onChange={() => {
                  handleDriverActiveStatus(
                    params.row.id,
                    params.row.driverActiveStatus,
                  );
                }}
              />
              <Typography fontSize={12}>
                {params.row.driverActiveStatus ? 'En servicio' : 'Descansando'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'firstName',
        type: 'string',
        headerName: 'Datos del conductor',
        width: 200,
        valueGetter: (value, row) => {
          return `${value} ${row.lastName}`;
        },
      },
      {
        field: 'mobile',
        type: 'string',
        headerName: 'Teléfono',
        width: 150,
      },
      {
        field: 'vehicleNumber',
        type: 'string',
        headerName: 'Placa',
        width: 70,
      },
      {
        field: 'walletBalance',
        type: 'numeric',
        headerName: 'Saldo',
        width: 100,
        renderCell: params => {
          return (
            <Button
              onClick={() => {
                handleWalletBalance(params.row.id, params.row.wallet);
              }}
            >
              ${params.row.walletBalance}
            </Button>
          );
        },
      },
      {
        field: 'queue',
        type: 'boolean',
        headerName: 'Ocupado',
        width: 70,
        renderCell: params => (
          <Android12Switch
            checked={params.row.queue}
            onChange={() => {
              handleUserService(params.id, !params.row.queue);
            }}
          />
        ),
      },

      {
        type: 'actions',
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            name="Eliminar conductor"
            title="Eliminar conductor"
            onClick={() => handleDeleteUser(params.id)}
            color="error"
          />,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    setCityTab(auth.profile.city ? auth.profile.city : 'Cúcuta');
    dispatch(fetchUsersOnce());
  }, []);


  const fetchDriversByCity = city => {
    if (!auth || !staticusers) return;

    // Solo continua si el usuario tiene un rol permitido
    if (
      ![Roles.FLEET_ADMIN, Roles.ADMIN, Roles.MANAGER].includes(
        auth.profile.usertype,
      )
    )
      return;

    // Filtramos para obtener solo los usuarios tipo DRIVER
    return staticusers.filter(user => {
      if (user.city === '' || user.city === null || !user.city) {
        user.city = 'Cúcuta';
      }
      return user.usertype === Roles.DRIVER && user.city === cityTab;
    });
  };

  useEffect(() => {
    loaded.current = false;
    const newRows = fetchDriversByCity(cityTab);
    loaded.current = true;
    // Actualizamos las filas con el resultado final
    setRows(newRows);

    loaded.current = true;
  }, [auth, staticusers, cityTab]);

  if (!loaded.current) {
    return <CircularLoading />;
  }

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Conductores
      </Typography>
      {auth.profile.usertype === Roles.ADMIN && (
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Ciudad</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Cúcuta"
            name="radio-buttons-group"
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value="Cúcuta"
              control={<Radio />}
              label="Cúcuta"
              onChange={() => setCityTab('Cúcuta')}
            />
            <FormControlLabel
              value="Bogotá"
              control={<Radio />}
              label="Bogotá"
              onChange={() => setCityTab('Bogotá')}
            />
            <FormControlLabel
              value="Sevilla"
              control={<Radio />}
              label="Sevilla"
              onChange={() => setCityTab('Sevilla')}
            />
            <FormControlLabel
              value="Medellín"
              control={<Radio />}
              label="Medellín"
              onChange={() => setCityTab('Medellín')}
            />
            <FormControlLabel
              value="Bucaramanga"
              control={<Radio />}
              label="Bucaramanga"
              onChange={() => setCityTab('Bucaramanga')}
            />
          </RadioGroup>
        </FormControl>
      )}

      <div style={{ height: '90%', width: '100%' }}>
        <DataGrid
          columns={columns}
          rows={rows}
          slots={{
            toolbar: () => (
              <Box sx={{ 
                p: 2,
                display: 'flex',
                justifyContent: 'space-between', 
                alignItems: 'center'
              }}>
                <GridToolbarQuickFilter
                  placeholder="Buscar..."
                />
                
                <GridToolbarExport />
              </Box>
              )
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                placeholder: 'Buscar...',
              },
            },
          }}
          localeText={{
            // Column filter translations
            columnMenuLabel: 'Menú de columnas',
            columnMenuShowColumns: 'Mostrar columnas',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar columna',
            columnMenuUnsort: 'Quitar orden',
            columnMenuSortAsc: 'Ordenar ascendente',
            columnMenuSortDesc: 'Ordenar descendente',

            // Filter panel translations
            filterPanelAddFilter: 'Añadir filtro',
            filterPanelDeleteIconLabel: 'Eliminar',
            filterPanelOperators: 'Operadores',
            filterPanelOperatorAnd: 'Y',
            filterPanelOperatorOr: 'O',
            filterPanelColumns: 'Columnas',
            filterPanelInputLabel: 'Valor',
            filterPanelInputPlaceholder: 'Valor para filtrar',

            // Filter operators translations
            filterOperatorContains: 'Contiene',
            filterOperatorEquals: 'Igual a',
            filterOperatorStartsWith: 'Empieza con',
            filterOperatorEndsWith: 'Termina en',
            filterOperatorIsEmpty: 'Está vacío',
            filterOperatorIsNotEmpty: 'No está vacío',
            filterOperatorIsAnyOf: 'Es alguno de',

            // Additional toolbar texts
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            toolbarQuickFilterPlaceholder: 'Buscar…',
            toolbarQuickFilterLabel: 'Buscar',
            // Textos de paginación
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsPerPage: 'Filas por página:',
              firstButtonAriaLabel: 'Primera página',
              lastButtonAriaLabel: 'Última página',
              nextButtonAriaLabel: 'Página siguiente',
              previousButtonAriaLabel: 'Página anterior',
            },

            // Textos de componentes
            toolbarDensity: 'Densidad',
            toolbarDensityLabel: 'Densidad',
            toolbarDensityCompact: 'Compacta',
            toolbarDensityStandard: 'Estándar',
            toolbarDensityComfortable: 'Cómoda',

            // Texto de filtro
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            toolbarQuickFilterPlaceholder: 'Buscar...',
            toolbarQuickFilterLabel: 'Buscar',

            // Texto de columnas
            columnMenuLabel: 'Menú',
            columnMenuSortAsc: 'Ordenar ascendente',
            columnMenuSortDesc: 'Ordenar descendente',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar columna',
            columnMenuShowColumns: 'Mostrar columnas',

            // Texto de errores
            errorOverlayDefaultLabel: 'Ocurrió un error',

            // Texto de sin resultados y sin filas
            noRowsLabel: 'No hay filas',
            noResultsOverlayLabel: 'Sin resultados',

            // Texto de exportación
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar EXCEL',
            toolbarExportPrint: 'Imprimir',

            // Texto de selección
            checkboxSelectionHeaderName: 'Selección',
            checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
            checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',

            // Texto de clasificación
            sort: {
              ascending: 'ascendente',
              descending: 'descendente',
            },

            // Tooltip de agrupación de columnas
            columnGroupingHeaderName: 'Agrupación de columnas',

            // Texto de rango de selección de celdas
            rangeSelectionRowLabel: 'Filas seleccionadas',

            // Otros textos comunes
            toolbarColumns: 'Columnas',
            toolbarColumnsLabel: 'Seleccionar columnas',
            toolbarColumnsTooltipShow: 'Mostrar columnas',
            toolbarColumnsTooltipHide: 'Ocultar columnas',
          }}
          initialState={{
            sorting: [
              {
                field: 'createdAt',
                order: 'desc',
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default DriverPage2;
