import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  REQUEST_OTP_SUCCESS,
  UPDATE_USER_WALLET_HISTORY,
  SEND_RESET_EMAIL,
  SEND_RESET_EMAIL_FAILED,
} from '../store/types';

import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set, off, get } from 'firebase/database';
import {
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { uploadBytes, getDownloadURL } from 'firebase/storage';
import base64 from 'react-native-base64';
import AccessKey from '../other/AccessKey';
import { Alert } from 'react-native';

export const fetchUser = () => dispatch => {
  const { auth, singleUserRef } = firebase;

  dispatch({
    type: FETCH_USER,
    payload: null,
  });
  onAuthStateChanged(auth, user => {
    if (user) {
      onValue(singleUserRef(user.uid), async snapshot => {
        if (snapshot.val()) {
          let profile = snapshot.val();
          profile.uid = user.uid;
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: profile,
          });
        } else {
          let mobile = '';
          let email = '';
          let firstName = '';
          let lastName = '';
          let verifyId = '';
          let profile_image = null;
          if (user.providerData.length == 0 && user.email) {
            email = user.email;
          }
          if (user.providerData.length > 0 && user.phoneNumber) {
            mobile = user.phoneNumber;
          }
          if (user.providerData.length > 0) {
            const provideData = user.providerData[0];
            if (provideData == 'phone') {
              mobile = provideData.phoneNumber;
            }
            if (
              provideData.providerId == 'facebook.com' ||
              provideData.providerId == 'apple.com'
            ) {
              if (provideData.email) {
                email = provideData.email;
              }
              if (provideData.phoneNumber) {
                mobile = provideData.phoneNumber;
              }
              if (provideData.displayName) {
                if (provideData.displayName.split(' ').length > 0) {
                  firstName = provideData.displayName.split(' ')[0];
                  lastName = provideData.displayName.split(' ')[1];
                } else {
                  firstName = provideData.displayName;
                }
              }
              if (provideData.photoURL) {
                profile_image = provideData.photoURL;
              }
            }
          }

          if (user.providerData.length > 0 && user.verifyId) {
            verifyId = user.verifyId;
          }

          const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const reference = [...Array(5)]
            .map(_ => c[~~(Math.random() * c.length)])
            .join('');
          let userData = {
            createdAt: new Date().getTime(),
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            email: email,
            usertype: 'customer',
            referralId: reference,
            approved: true,
            walletBalance: 0,
            verifyId: verifyId,
          };
          if (profile_image) {
            userData['profile_image'] = profile_image;
          }
          set(singleUserRef(user.uid), userData);
          userData.uid = user.uid;
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: userData,
          });
        }
      });
    } else {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: {
          code: store.getState().languagedata.defaultLanguage.auth_error,
          message: store.getState().languagedata.defaultLanguage.not_logged_in,
        },
      });
    }
  });
};

export const registerUserState = newUserUid => dispatch => {
  dispatch({
    type: 'newUser',
    payload: { uid: newUserUid },
  });
};

export const validateReferer = async referralId => {
  const { config } = firebase;
  const response = await fetch(
    `https://${config.projectId}.web.app/validate_referrer`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referralId: referralId,
      }),
    },
  );
  const json = await response.json();
  return json;
};

export const checkUserExists = async data => {
  const { config } = firebase;

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/check_user_exists`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + base64.encode(config.projectId + ':' + AccessKey),
        // "Access-Control-Allow-Origin": "https://taxiok.app",
        // "origin": "https://taxiok.app",
      },
      body: JSON.stringify({
        email: data.email,
        mobile: data.mobile,
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.log('error', JSON.stringify('error'));
  }
};

export const updateProfileWithEmail = profileData => async dispatch => {
  const { config } = firebase;

  try {
    const settings = store.getState().settingsdata.settings;
    let host =
      window &&
      window.location &&
      settings.CompanyWebsite === window.location.origin
        ? window.location.origin
        : `https://${config.projectId}.web.app`;
    let url = `${host}/update_user_email`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + base64.encode(config.projectId + ':' + AccessKey),
      },
      body: JSON.stringify(profileData),
    });
    const result = await response.json();
    if (result.error) {
      return { success: false, error: result.error };
    }
  } catch (error) {
    return { success: false, error: error };
  }
};

export const requestPhoneOtpDevice = verificationId => async dispatch => {
  dispatch({
    type: REQUEST_OTP_SUCCESS,
    payload: verificationId,
  });
};

export const mobileSignIn = (verficationId, code) => dispatch => {
  const { auth, mobileAuthCredential } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  });
  signInWithCredential(auth, mobileAuthCredential(verficationId, code))
    .then(user => {
      //OnAuthStateChange takes care of Navigation
    })
    .catch(error => {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error,
      });
    });
};

export const facebookSignIn = token => dispatch => {
  const { auth, facebookProvider, facebookCredential } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  });
  if (token) {
    const credential = facebookCredential(token);
    signInWithCredential(auth, credential)
      .then(user => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch(error => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        });
      });
  } else {
    signInWithPopup(auth, facebookProvider)
      .then(function (result) {
        var token = result.credential.accessToken;
        const credential = facebookCredentialFromResult(token);
        signInWithCredential(auth, credential)
          .then(user => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch(error => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            });
          });
      })
      .catch(function (error) {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        });
      });
  }
};

export const appleSignIn = credentialData => dispatch => {
  const { auth, appleProvider } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  });
  if (credentialData) {
    const credential = appleProvider.credential(credentialData);
    signInWithCredential(auth, credential)
      .then(user => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch(error => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        });
      });
  } else {
    signInWithPopup(auth, appleProvider)
      .then(function (result) {
        signInWithCredential(auth, result.credential)
          .then(user => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch(error => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            });
          });
      })
      .catch(function (error) {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        });
      });
  }
};

export const signOff = () => dispatch => {
  const { auth, singleUserRef, walletHistoryRef, userNotificationsRef } =
    firebase;

  const uid = auth.currentUser.uid;

  off(singleUserRef(uid));
  off(walletHistoryRef(uid));
  off(userNotificationsRef(uid));

  onValue(
    singleUserRef(uid),
    snapshot => {
      if (snapshot.val()) {
        const profile = snapshot.val();
        if (profile && profile.usertype === 'driver') {
          update(singleUserRef(uid), { driverActiveStatus: false });
        }
        setTimeout(() => {
          signOut(auth)
            .then(() => {
              dispatch({
                type: USER_SIGN_OUT,
                payload: null,
              });
            })
            .catch(error => {});
        }, 2000);
      }
    },
    { onlyOnce: true },
  );
};

export const updateProfile =
  (updateData, refId = null) =>
  async dispatch => {
    const {
      auth,
      singleUserRef,
      driverDocsRef,
      driverDocsRefBack,
      driverOperatingCardRef,
      driverOwnershipCardRef,
      driverTarjetonRef,
      profileImageRef,
      driverOwnershipCardBackRef,
    } = firebase;

    const uid = refId == null ? auth.currentUser.uid : refId;

    try {
      if (updateData.profileImg) {
        await uploadBytes(profileImageRef(uid), updateData.profileImg);
        const response = await getDownloadURL(profileImageRef(uid));
        updateData.profileImg = response;
        updateData.profile_image = response;
      }
      if (updateData.licenseImage) {
        await uploadBytes(driverDocsRef(uid), updateData.licenseImage);
        const response = await getDownloadURL(driverDocsRef(uid));
        updateData.licenseImage = response;
      }
      if (updateData.licenseImageBack) {
        await uploadBytes(driverDocsRefBack(uid), updateData.licenseImageBack);
        const response = await getDownloadURL(driverDocsRefBack(uid));
        updateData.licenseImageBack = response;
      }
      if (updateData.operatingCard) {
        await uploadBytes(
          driverOperatingCardRef(uid),
          updateData.operatingCard,
        );
        response = await getDownloadURL(driverOperatingCardRef(uid));

        updateData.operatingCard = response;
      }
      if (updateData.ownershipCard) {
        await uploadBytes(
          driverOwnershipCardRef(uid),
          updateData.ownershipCard,
        );
        const response = await getDownloadURL(driverOwnershipCardRef(uid));
        updateData.ownershipCard = response;
      }
      if (updateData.ownershipCardBack) {
        await uploadBytes(
          driverOwnershipCardBackRef(uid),
          updateData.ownershipCardBack,
        );
        const response = await getDownloadURL(driverOwnershipCardBackRef(uid));
        updateData.ownershipCardBack = response;
      }
      if (updateData.tarjeton) {
        await uploadBytes(driverTarjetonRef(uid), updateData.tarjeton);
        const response = await getDownloadURL(driverTarjetonRef(uid));
        updateData.tarjeton = response;
      }
      const updateUser = await update(singleUserRef(uid), updateData);
      return updateUser;
    } catch (error) {
      console.log('error', error);
    }
  };

export const createUser = async userData => {
  const { auth } = firebase;

  try {
    // Crea el usuario con el correo electrónico y la contraseña proporcionados
    const { user } = await auth.createUserWithEmailAndPassword(
      userData.email,
      userData.password,
    );

    // Añade el usuario a la base de datos con un nodo correspondiente a su uid
    await database.ref(`users/${user.uid}`).set({
      email: userData.email,
    });

    // Aquí puedes realizar otras acciones después de que se haya registrado el usuario
  } catch (error) {
    // Manejo de errores
    setError(error.message);
  }
};

export const registerProfileImage = (imageBlob, uid) => {
  const { auth, singleUserRef, profileImageRef } = firebase;

  uploadBytes(profileImageRef(uid), imageBlob)
    .then(() => {
      imageBlob.close();
      return getDownloadURL(profileImageRef(uid));
    })
    .then(url => {
      update(singleUserRef(uid), {
        profile_image: url,
      });
    });
};

export const updateProfileImage = imageBlob => {
  const { auth, singleUserRef, profileImageRef } = firebase;

  const uid = auth.currentUser.uid;

  uploadBytes(profileImageRef(uid), imageBlob)
    .then(() => {
      imageBlob.close();
      return getDownloadURL(profileImageRef(uid));
    })
    .then(url => {
      update(singleUserRef(uid), {
        profile_image: url,
      });
    });
};

export const updateWebProfileImage = async imageBlob => {
  const { auth, singleUserRef, profileImageRef } = firebase;

  const uid = auth.currentUser.uid;

  await uploadBytes(profileImageRef(uid), imageBlob);
  let image = await getDownloadURL(profileImageRef(uid));
  update(singleUserRef(uid), { profile_image: image });
};

export const uploadPictureToUserData = async (
  imageData,
  userUID,
  imageObjectKey,
) => {
  const {
    singleUserRef,
    profileImageRef,
    driverDocsRef, // Licencia frontal
    driverDocsRefBack, // Licencia trasera
    driverOperatingCardRef,
    driverOwnershipCardRef,
    driverTarjetonRef,
    driverOwnershipCardBackRef,
    driverOcassionalTripDocument,
  } = firebase;

  let imageRef;

  // Asigna la referencia según el tipo de imagen
  switch (imageObjectKey) {
    case 'profile_image':
      imageRef = profileImageRef;
      break;
    case 'licenseImage':
      imageRef = driverDocsRef;
      break;
    case 'licenseImageBack':
      imageRef = driverDocsRefBack;
      break;
    case 'operatingCard':
      imageRef = driverOperatingCardRef;
      break;
    case 'ownershipCard':
      imageRef = driverOwnershipCardRef;
      break;
    case 'ownershipCardBack':
      imageRef = driverOwnershipCardBackRef;
      break;
    case 'tarjeton':
      imageRef = driverTarjetonRef;
      break;
    case 'ocassionalTripDocument':
      imageRef = driverOcassionalTripDocument;
      break;
    default:
      throw new Error(`Invalid imageObjectKey: ${imageObjectKey}`);
  }
  // Sube la imagen y obtiene la URL
  await uploadBytes(imageRef(userUID), imageData);
  const imageURL = await getDownloadURL(imageRef(userUID));

  // Actualiza la información del usuario en la base de datos
  await update(singleUserRef(userUID), { [imageObjectKey]: imageURL });
};

export const updatePushToken = (token, platform) => {
  const { auth, singleUserRef } = firebase;

  const uid = auth.currentUser.uid;

  update(singleUserRef(uid), {
    pushToken: token,
    userPlatform: platform,
  });
};

export const clearLoginError = () => dispatch => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
    payload: null,
  });
};

export const fetchWalletHistory = () => dispatch => {
  const { auth, walletHistoryRef } = firebase;

  const uid = auth.currentUser.uid;

  onValue(walletHistoryRef(uid), snapshot => {
    const data = snapshot.val();
    if (data) {
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: UPDATE_USER_WALLET_HISTORY,
        payload: arr.reverse(),
      });
    }
  });
};

export const sendResetMail = email => async dispatch => {
  try {
    const { authRef } = firebase;

    dispatch({
      type: SEND_RESET_EMAIL,
      payload: email,
    });

    await sendPasswordResetEmail(authRef(), email);

    Alert.alert(
      'Email enviado',
      'Revisa tu correo para restablecer tu contraseña',
    );
  } catch (error) {
    dispatch({
      type: SEND_RESET_EMAIL_FAILED,
      payload: error,
    });
  }
};

export const mainSignUp = async regData => {
  const { config } = firebase;
  let url = `https://${config.projectId}.web.app/user_signup`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ regData: regData }),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log('error SINGUP', error.message);
  }
};

export const verifyEmailPassword = (email, pass) => async dispatch => {
  const { authRef } = firebase;

  try {
    const response = await signInWithEmailAndPassword(authRef(), email, pass);

    dispatch({
      type: USER_SIGN_IN_SUCCESS,
      payload: response.user, // Aquí puedes manejar el usuario autenticado
    });

    return response.user; // Retornamos el usuario autenticado
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: USER_SIGN_IN_FAILED,
      payload: error,
    });

    return null; // Retornamos null en caso de error
  }
};

export const verifyEmailPasswordRegister =
  (email, pass, registerFunction) => async dispatch => {
    const { authRef } = firebase;

    let user;

    signInWithEmailAndPassword(authRef(), email, pass)
      .then(user => {
        user = user;
      })
      .catch(error => {
        console.log('error', error);
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        });
      })
      .finally(res => {
        registerFunction(authRef().currentUser.uid);
      });

    return user;
  };

export const asyncVerifyEmailPassword = async (email, pass) => {
  const { authRef } = firebase;

  return signInWithEmailAndPassword(authRef(), email, pass);
};

export const getLoggedUser = () => {
  const { authRef } = firebase;

  return authRef().currentUser;
};

export const resetUserPassword = async (userId, newPassword) => {
  const { config } = firebase;

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/updateUserPassword`;

  const apiUrl = `https://us-central1-taxiok-seed.cloudfunctions.net/updateUserPassword`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + base64.encode(config.projectId + ':' + AccessKey),
      },
      body: JSON.stringify({ userId, newPassword }),
    });

    // Verificar si la respuesta HTTP fue exitosa
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error in response:', errorData);
      throw new Error(
        `Failed to reset password: ${response.status} ${response.statusText}`,
      );
    }

    // Parsear la respuesta
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error resetting user password:', error);
    throw error;
  }
};
